import Vue from "vue"
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import MenuList from '../widgets/menu.vue'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    MenuList,
    Paginate
  },
  data() {
    return {
      loading: false,
      busy: false,
      sortBy: 'name',
      perPage: 6,
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      keyword: "",
      create: {
        Id: null,
        mode: 'create',
        state: false
      },
      info: {
        Id: null,
        state: false
      },
      fields: [
        { 
          key: 'code', 
          label: 'รหัสงาน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'customer', 
          label: 'ชื่อลูกค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'price', 
          label: 'ราคา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'duedate', 
          label: 'ราคา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          class: 'text-center td-top text-xs-1/2' 
        }
      ],
      items: [
        {
          code: "IV-660500001",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 1,
        },
        {
          code: "IV-660500002",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 2,
        },
        {
          code: "IV-660500003",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 3,
        },
        {
          code: "IV-660500004",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 4,
        },
        {
          code: "IV-660500005",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 5,
        },
        {
          code: "IV-660500006",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 6,
        },
        {
          code: "IV-660500007",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 7,
        },
        {
          code: "IV-660500008",
          customer: "คุณเซฟ",
          price: "12,345",
          duedate: "15 ส.ค. 2532",
          status: 8,
        },
      ],
      orderStatus: [
        { text: "รออนุมัติ", value: 1 },
        { text: "รอชำระเงิน", value: 2 },
        { text: "กำลังผลิต", value: 3 },
        { text: "รอจัดส่ง", value: 4 },
        { text: "กำลังจัดส่ง", value: 5 },
        { text: "ส่งสำเร็จ", value: 6 },
        { text: "ยกเลิก", value: 7 },
        { text: "คืนสินค้า", value: 8 },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData(1)
    })
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: { 
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.onConfirmDelete(id)
        }
      })
    },
    async confirmDelete(id) {
      try {
        const param = {
          _id: id
        }
        const result = await this.$store.dispatch('Branches/delete', param, { root: true })
        this.loading = false
        if (result.status === 204) {
          this.$swal({
            width: "29rem",
            padding: '1.825em',
            html: '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
              '<div class="d-flex flex-column">'+
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
              '<span class="text-base text-left text-gray-500">ลบข้อมูลเรียบร้อยแล้ว</span>'+
              '</div>'+
            '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              cancelButtonColor: '#dc4a38',
              cancelButtonText: 'ยกเลิก',
              confirmButtonText: "ตกลง",
              showCancelButton: false,
              showCloseButton: true,
          }).then(async confirm => {
            if(confirm.value){
              this.onInitData(1)
            }
          })
        }
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onCreateQuotation() {
      this.$emit('tabIndex', { 
        index: 1
      })
    },
    onMenuIndexChange(event) {
      this.$emit('tabIndex', { 
        index: event.index
      })
    },
    oncreate() {
      this.create.Id = null
      this.create.mode = 'create'
      this.create.state = !this.create.state
    },
    onupdate(id) {
      this.create.Id = id
      this.create.mode = 'update'
      this.create.state = !this.create.state
    },
    onInfo(id) {
      this.info.Id = id
      this.info.state = !this.info.state
    },
    handleCreateEvent(event) {
      if (event.status) {
        this.create.state = false
        setTimeout(() => {
        const currentPage = this.currentPage
        this.onInitData(currentPage)
        }, 500)
      }
    },
    handleInfoEvent(event) {
      if (event.status) {
        this.info.state = false
      }
    },
    onOpenQuotation(no) {
      this.$emit('tabIndex', { 
        index: 2,
        doc_no: no
      })
    },
    onOpenInvoice(no) {
      this.$emit('tabIndex', { 
        index: 3,
        doc_no: no
      })
    },
    onOpenRefund(no) {
      this.$emit('tabIndex', { 
        index: 4,
        doc_no: no
      })
    },
    onOpenPurchaseOrder(no) {
      this.$emit('tabIndex', { 
        index: 5,
        doc_no: no
      })
    },
    async onInitData(currentPage) { }
  }
}
