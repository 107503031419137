import Vue from "vue"
import Products from '@/components/widgets/products'
import Customers from '@/components/widgets/customers'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from 'vue2-datepicker'
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    DatePicker,
    Customers,
    Products
  },
  data() {
    return {
      loading: false,
      busy: false,
      productOpened: false,
      customerOpened: false,
      form: {
        quotation_date: new Date(),
        quotation_duedate: null,
        quotation_expire: null,
        delivery_type: 1,
        payment_terms: 1,
      },
      fields: [
        { 
          key: 'code', 
          label: 'รหัสสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'name', 
          label: 'ชื่อสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'cost', 
          label: 'ต้นทุน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2' 
        },
        { 
          key: 'price', 
          label: 'ราคาขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2' 
        },
        { 
          key: 'qty', 
          label: 'จำนวน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2' 
        },
        { 
          key: 'description', 
          label: 'รายละเอียด',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2' 
        },
        { 
          key: 'lot', 
          label: 'Lot.', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2'  
        },
        { 
          key: 'category', 
          label: 'หมวดหมู่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-center text-xs-1/2'  
        },
        { 
          key: 'type', 
          label: 'ประเภทสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-center text-xs-1/2' 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          class: 'text-center td-top text-xs-1/2' 
        }
      ],
      products: [],
      customer: {}
    }
  },
  computed: {
    rows() {
      return this.products.length
    }
  },
  methods: {
    onreset() {
      this.customer = {}
      this.products = []
    },
    onselectCustomer() {
      this.customerOpened = !this.customerOpened
    },
    onselectProduct() {
      this.productOpened = !this.productOpened
    },
    onInitProducts(event) {
      this.productOpened = false
      if (event.status) {
        this.firstOrupdate(event.data)
      }
    },
    onInitCustomer(event) {
      this.customerOpened = false
      if (event.status) {
        this.customer = event.data
      }
    },
    firstOrupdate(items) {
      if(this.products.length === 0) {
        this.products = items
      } else {
        for (let index = 0; index < items.length; index++) {
          const element = items[index]
          const product = _.find(this.products, { uuid: element.uuid })
          if (!product) {
            this.products.push(element)
          }
        }
      }
    },
    onRemoveProduct(id) {
      const index = _.findIndex(this.products, function(o) { return o.uuid == id })
      this.products.splice(index, 1)
    },
    onProductClose() {
      this.onreset()
      this.$emit('tabIndex', { 
        index: 0
      })
    },
    onSubmitForm() {
      this.onreset()
      this.$emit('tabIndex', { 
        index: 0
      })
    }
  }
}
