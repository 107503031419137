<template>
  <div
   id="sale-menu" 
    v-click-outside="execute"
    class="cursor-pointer w-auto">
    <i class="mdi mdi-dots-vertical mdi-18px" @click="menuList"></i>
    <ul 
      ref="menu"
      class="bg-white list no-bullets position-absolute px-3 py-2 shadow z-index-999">
      <li class="text-sm mb-1 py-1">
        <i class="mdi mdi-content-paste mr-2"></i>
        <span class="text-gray-600" @click="onInitPo">สร้างใบผลิต</span>
      </li>
      <li class="text-sm mb-1 py-1">
        <i class="mdi mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600" @click="onInitInvoice">สร้างใบแจ้งหนี้</span>
      </li>
      <li class="text-sm mb-1 py-1">
        <i class="mdi mdi-content-paste mr-2"></i>
        <span class="text-gray-600" @click="onInitPo">สร้างใบเสร็จ</span>
      </li>
      <li class="text-sm mb-1 py-1">
        <i class="mdi mdi mdi-file-export mr-2"></i>
        <span class="text-gray-600" @click="onInitRefund">สร้างใบคืนสินค้า</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue"
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      state: false
    }
  },
  methods: {
    execute() {
      this.state = this.state? !this.state: this.state
      if (!this.state) {
        this.hideMenu()
      }
    },
    menuList() {
      this.state = !this.state
      if (this.state) {
        this.showMenu()
      } else {
        this.hideMenu()
      }
    },
    showMenu() {
      const el = this.$refs.menu
      el.classList.add('animation-menu-list')
    },
    hideMenu() {
      const el = this.$refs.menu
      el.classList.remove('animation-menu-list')
    },
    onInitPo() {
      this.$emit('tabIndex', { 
        index: 6
      })
    },
    onInitInvoice() {
      this.$emit('tabIndex', { 
        index: 7
      })
    },
    onInitRefund() {
      this.$emit('tabIndex', { 
        index: 8
      })
    }
  }
}
</script>

<style scoped>
#sale-menu {
  position:relative;
}
 
#sale-menu ul.list {
  position:absolute;
  bottom:0px;
  right:0px;
  height: fit-content;
    /* top: 105px;
    right: 63px;
    width: 170px;
    display: none;
    text-align: left; */
}
ul.animation-menu-list {
  display: block !important;
  transition: all;
  -webkit-animation: fadeInDown 0.1s linear forwards; /* Chrome, Safari, Opera */
  animation: fadeInDown 0.1s linear forwards;
}
/* Standard syntax */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
</style>
