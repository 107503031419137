import Vue from "vue"
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loading,
    Paginate
  },
  data() {
    return {
      loading: false,
      textInput: '',
      answer: 'พิมพ์ รหัสผู้ขาย, ชื่อ - นามสกุล สำหรับค้นหา',
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'code', 
          label: 'รหัสผู้ขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2 py-0.5',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'ชื่อผู้ขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 py-0.5',
          sortable: true 
        },
        { 
          key: 'address', 
          label: 'ที่อยู่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2 py-0.5' 
        },
        { 
          key: 'tel', 
          label: 'เบอร์โทรศัพท์', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-left text-xs-1/2 py-0.5'  
        },
        { 
          key: 'email', 
          label: 'อีเมล์', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-center text-xs-1/2 py-0.5'  
        },
        { 
          key: 'action', 
          label: '#', 
          sortable: true, 
          thClass: 'font-weight-bold text-right text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2 py-0.5'  
        }
      ],
      items: []
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.items = []
        this.onSearchHandler(this.currentPage) 
      }
    }
  },
  methods: {
    onSelected(item) {
      this.$emit('onSupplierCloseHandler', {
        status: true,
        data: item
      })
    },
    onClose() {
      this.$emit('onSupplierCloseHandler', {
        status: false
      })
    },
    async onSearchHandler(currentPage) {
      this.loading = true
      this.answer = 'กำลังค้นหาข้อมูล...'
      const page = currentPage ? currentPage : 1
      const url = `${Vue.prototype.$host}/api/v1/supplier?page=${page}&perPage=8&keyword=${this.textInput}`
      const param = {
        keyword: this.keyword,
        url: url
      }
      const result = await this.$store.dispatch('Suppliers/search', param, { root: true })
      this.loading = false
      // this.answer = 'พิมพ์ รหัสผู้ขาย, ชื่อ - นามสกุล สำหรับค้นหา'
      if (result) {
        this.currentData = result.data;
        this.items = result.data.data
      } else {
        this.items = []
        this.onExceptionHandler()
      }
    }
  }
}
