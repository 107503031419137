import Vue from "vue"
import Products from '@/components/widgets/products'
import Suppliers from '@/components/widgets/suppliers'
import Documents from '@/components/widgets/documents'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from 'vue2-datepicker'
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    DatePicker,
    Products,
    Suppliers,
    Documents
  },
  data() {
    return {
      onselectOpened: false,
      form: {
        purchase_no: '',
        purchase_date: new Date(),
        purchase_duedate: null,
        purchase_credit: null,
        purchase_payment_terms: '',
        purchase_note: ''
      },
      fields: [
        { 
          key: 'code', 
          label: 'รหัสสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'name', 
          label: 'ชื่อสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'cost', 
          label: 'ต้นทุน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2' 
        },
        { 
          key: 'price', 
          label: 'ราคาขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2' 
        },
        { 
          key: 'description', 
          label: 'รายละเอียด',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2' 
        },
        { 
          key: 'qty', 
          label: 'จำนวน',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2' 
        },
        { 
          key: 'lot', 
          label: 'Lot.', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2'  
        },
        { 
          key: 'category', 
          label: 'หมวดหมู่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-center text-xs-1/2'  
        },
        { 
          key: 'type', 
          label: 'ประเภทสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-center text-xs-1/2' 
        }
      ],
      products: [],
      supplierFields: [
        { 
          key: 'code', 
          label: 'รหัส', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'name', 
          label: 'ชื่อ - นามสกุล', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'address', 
          label: 'ที่อยู่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'tax_id', 
          label: 'เลขประจำตัวผู้เสียภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'contact', 
          label: 'ติดต่อ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        }
      ],
      supplier: []
    }
  },
  methods: {
    onPurchaseOrderClose() {
      this.onreset()
      this.$emit('tabIndex', { 
        index: 0
      })
    },
    onselectDocument() {
      this.onselectOpened = !this.onselectOpened
    },
    onSubmitForm() {
      this.onreset()
      this.$emit('tabIndex', { 
        index: 0
      })
    },
    onreset() {
      this.products = []
      this.supplier = []
    },
    onInitDocuments(event) {
      this.onselectOpened = false
      if (event.status) {
        this.products = []
        const data = event.data
        this.supplier = []
        if (data.supplier && data) {
          this.supplier.push(data.supplier)
        }
        this.products = [...data.products]
      }
    }
  }
}